html{
  background-color: #202020;
}

.App {
  text-align: center;
  background-color: #202020;
  font-size: 1vw;
  overflow-y: hidden;
  max-height: 100vh;
  
}
.menuCard{
  background-color: rgb(184, 63, 93);

}
.App-content {
  text-align: center;
  min-height: 100vh;
  color: white;
  max-width: 100vw;
  overflow-x: hidden;
}
.App-logo{
  animation: spin linear 2s infinite;
}
.vendor-message{
  color: wheat;
  margin-top: 4vh;
}

.HomePage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MealDealHome{
  display: flex;

}

.topSection{
  display: flex;
  width: 100vw;
  justify-content: space-evenly;

}

.AnnouncementsHome{
  display: flex;
  flex-direction: column;

  width: 95%;
  align-items: center;
  border-style: solid;
  border-color: rgb(184, 63, 93);
  border-radius: 15px;
  overflow-y: scroll;
  scrollbar-color: rgb(184, 63, 93) black;
}
.announcement-title{
  margin-top: 3.5%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.announcement-smaller-title{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: rgb(165, 18, 102);
}

.announcement-card{
  background-color: beige;
  margin-top: 2%;
  width: 95%;
  padding: 1%;
  border: 3px solid rgb(165, 18, 102);
  border-radius: 15px;
  margin-bottom: 1%;
}
.announcement-text{
  color: black;
}
@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.input-ingredients{
  width: 100%
}
.newButton{
  border-radius: 15px;
  font-size: 2vw;
  margin-left: 50%;
  height: 5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  border-style: solid;
  border-color: #202020;
  align-self: center;
  background-color:rgb(240, 194, 208);
}