@media screen and (max-width: 639px) {
    .title{
        font-size: 8vw;
        -webkit-text-stroke: 0.1vw white;
        -webkit-text-fill-color: rgb(102, 7, 47);
      }
      .smaller-title{
        font-size: 6vw;
        -webkit-text-stroke: 0.1vw white;
        -webkit-text-fill-color: rgb(102, 7, 47);
      }
      .title-left{
        position: sticky;
        top: 0;
        background-color: #202020;
        padding: 2vh;
        font-size: 8vw;
        -webkit-text-stroke: 0.1vw white;
        -webkit-text-fill-color: rgb(102, 7, 47);
        margin-left: 1vw;
        text-align: left;
      }
      .title-left img{
        width: 5vw;
        height: 5vw;
      }
      .nav{
        top: 0;
        width: 100vw;
        background-color: rgb(248, 242, 232);
        font-size: 3vw;
      }
      .cardsArea{
        display: grid;
        grid-template-columns: 50vw 50vw;
        align-items: center;

      }
      .showImg{
        width: 0;
        height: 0;
      }
      .menuCard{
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1vh;
        margin-bottom: 1vh;
        font-size: 3vw;
        font-weight: bold;
        padding-top: 2vw;
        color: aliceblue ;
        width: 80%;
        max-height:fit-content;
        box-shadow: 0.5vw 0.5vw 0.5vw rgb(97, 97, 100);
        border-style: solid;
        border-color: wheat;
      
      
      }
      .dealCardArea{
        display: grid;
        grid-template-columns: 50vw, 50vw;
        justify-content: center;
      }
      .dealCard{
        background-color: rgb(184, 63, 93);
        border-radius: 15px;
        border-style: solid;
        border-color: wheat;
        font-size: 3vw;
        height: 100%;
        width: 95%;
        margin-top: 4vh;
        margin-bottom: 1vw;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
      .drinks-card{
        margin:3vw;
        background-color: rgb(184, 63, 93);
        color: #ffffff;
        text-align: left;
        font-size: 3vw;
        border-style: solid;
        border-color: aliceblue;
        width: fit-content;
        padding: 1.5vw;
        box-shadow: 0.5vw 0.5vw 1vw 1vw #ffffff56;
      }
      .drinks{
        margin: 2.5vw 
      }
      .viewButton{
        width: 50%;
        border-radius: 15px;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        border-style: solid;
        border-color: #202020;
        align-self: center;
        background-color:rgb(240, 194, 208);
      }
      input{
        width: 30vw;
      }
      
      .contactContent{
        display: inline-block;
      }
      .aboutDetails{
        text-align: left;
        margin-top: 4vw;
        font-size: 1.5vw;
        display: inline-block;
      }
      .hours-text, .hours-time{
          font-size: 3.5vw;
          text-align: center;
      }
      .hours-subtext{
        font-size: 2vw;
        text-align: center;
      }
      .about-img{
        width: 100%;
      }
      .fb-link{
          font-size: 4.5vw;
          text-align: center;
      }
      .hours-title{
          font-size: 5vw;
      }
      .contactDetails{
        grid-column-start: 2;
        text-align: left;
        margin-top: 5vh;
        font-size: 1.5vw;
      }
      .contactImage{
        margin-top: 5vh;
        height: 40vh;
      }
      .userDetails{
        margin-top: 10vh;
        display: grid;
        grid-template-columns: 100vw;
        grid-template-rows: 25vh 25vh;
      
      }
      .accountHeader{
        display: grid;
        grid-template-columns: 20vw 20vw 20vw 20vw 20vw;
        
      }
      .logout{
        grid-column-start: 5;
        width: fit-content;
        height: fit-content;
        background-color: rgb(183, 55, 85);
        border-color: #ffffff;
        color: #ffffff;
        font-size: 4vw;
        margin: 1vw;
      }
      .login-form{
        margin-top: 5vh;
      }
      .login-form input{
        width: 80vw;
        font-size: 4vw;
      }
      .login-form label, .login-form button, .login-form p{
        font-size: 4vw;
      }
      .login-form button{
        background-color: rgb(183, 55, 85);
        border-color: #ffffff;
        color: #ffffff;
      }
      .vendor-message a{
        color: rgb(184, 63, 93);
      }
      .vendor-message span{
        font-size: 4vw;
      }
      .editButton, .deleteButton{
        background-color: rgb(183, 55, 85);
        border-color: #ffffff;
        color: #ffffff;
        font-size: 4vw;
        margin-left: 1vw;
      }

      .scroll::-webkit-scrollbar {
        display: none;
      }

      .meal-deal-menu-card{
        min-height: 20vh;
        height: 20vh;
        max-height: fit-content;
      }
      .topSection{
        flex-direction: column;
        align-items: center;
        margin-top: 2vh;
      }
      .AnnouncementsHome{
        max-height: 25vh;
        min-height: 15vh;
        border-width: 5px;
      }
      .announcement-card{
        padding: 3%;
      }
      .announcement-title{
        font-size: 6vw;
        text-shadow: 0 0 5px red;
      }
      .announcement-smaller-title{
        font-size: 4.5vw;

      }
      .announcement-text{
        font-size: 3vw;
        
      }

     
}